import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getEventId } from "@/util/globalFunction";

const DEFAULT_FORM_STATE = {
  name: null,
  email: null,
  profile: {},
  phone: null,
  user_id: null,
  event_id: null,
  _method: 'post',
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Email",
    sortable: false,
    value: 'email',
  },
  {
    text: "Phone",
    sortable: false,
    value: 'phone',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  data() {
    return {
      operationTitle: 'SubGuestUsers',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      listUrl: '/sub/guest/users',
      columns: COLUMN_DEFINATION(this),
      file: {},
      dropdowns: {
        superUsers: [],
        events: [],
        masterGuestUser: [],
      },
      globalEventId: getEventId() ? getEventId() : null
    };
  },
  methods: {
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let self = this;
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      let self = this;

      self.formFields.profile = self.getFile;

      if ( !this.globalEventId || ( !this.$global.hasPermission('masterguestuser') && !this.$global.hasPermission('subguestuser') ) ) {
        this.formFields.event_id = this.formFields.event_id;
      } else {
        this.formFields.event_id = this.globalEventId;
      }

      try {
        const response = await request({
          url: this.formFields.id ? 'sub/guest/users/update' : 'sub/guest/users/create',
          method: 'post',
          data: {
            ...this.formFields,
          },
        });
        const { data } = response.data;

        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/sub/guest/users/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/sub/guest/users/detail/${ id }`,
        });
        this.operationTitle = 'Edit SubGuestUser';
        const { data } = response.data;
        this.formFields = {
          ...data,
          property_id: data.property ? data.property.id : null,
        };
        this.file = data.profile_pic;
        if ( data.user_id ) {
          await this.getEventByUserList();
          this.formFields.event_id = data.events ? data.events[0].id : null;

        }
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async getSuperUsers() {
      try {
        const response = await request({
          url: '/dropdowns/super/user',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.superUsers = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getEventByUserList() {
      try {
        const response = await request({
          url: '/dropdowns/events',
          method: 'post',
          data: {
            filter: {
              'user_id': this.formFields.user_id,
            }
          }
        });

        const { data } = response.data;
        this.dropdowns.events = data.map((item) => {
          return {
            value: item.id,
            text: item.title,
          };
        });
      } catch (e) {

      }
    },
    async getMasterGuestUsers() {
      try {
        const response = await request({
          url: '/dropdowns/master/guest/user',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.masterGuestUser = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "subGuestUserList" });
    },
  },
  computed: {
    getFile() {
      return this.file;
    }
  }
};
